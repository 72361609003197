const esTranslations = {

	/**
	  * Global
	  */
	enviar: "Enviar",
	cancelar: "Cancelar",
	continuar: "Continuar",
	guardar: "Guardar",
	volver: "Volver",
	editar: "Editar",
	cargando: "Cargando ...",

	/**
	  * Nav
	  * Shop/components/Nav y FilterNav
	  */
	notProducts: "Sin Productos aún",
	search: "Buscar",
	myShopping: "Mis Compras",
	hello: "Hola",
	messages: "Mensajes",
	inquieryBasket: "Carrito",
	products: "Productos",
	aboutUs: "Acerca de nosotros",
	homePage: "Inicio",
	sign_in: "Iniciar Sesión",
	join: "Registrarse",
	logout: "Cerrar Sesión",
	//FilterNav
	filtros: "Filtros",
	cerrar: "Cerrar",
	categorias: "Categorías",
	departamentos: "Departamentos",
	ordenar_por: "Ordenar por",
	precio_bajo_alto: "Precio bajo-alto",
	precio_alto_bajo: "Precio alto-bajo",
	mas_nuevo: "Más nuevo",

	/**
	  * Home
	  * Shop/components/Home
	  */
	bestSeller: "Lo más vendido",
	new: "Nuevo",
	recentlyView: "Vistos Recientes",

	/**
	  * Footer
	  * Shop/components/Footer y Footer2
	  */
	nombre: "Nombre",
	correo: "Correo",
	mensaje: "Mensaje",
	servicioCliente: "Servicio al cliente",
	envianosCorreo: "Envianos un correo",
	uneteAFamilia: "Únete a la familia  para recibir noticias y novedades de nuestros productos",
	quienesSomos: "¿Quiénes somos?",
	avisoPrivacidad: "Aviso de privacidad",
	trabajaConNosotros: "Trabaja con nosotros",
	paraClientes: "Para Clientes",
	pagosEnvios: "Pagos y envíos",
	politicasCompra: "Políticas de compra",
	redesSociales: "Redes Sociales",
	todosLosDerechosReservados: "Todos los derechos reservados",

	/**
	  * Banner2.js
	  * Shop/components/Home/BAnner2.js
	  */
	enero: "Enero",
	febrero: "Febrero",
	marzo: "Marzo",
	abril: "Abril",
	mayo: "Mayo",
	junio: "Junio",
	julio: "Julio",
	agosto: "Agosto",
	septiempre: "Septiempre",
	octubre: "Octubre",
	noviembre: "Noviembre",
	diciembre: "Diciembre",
	increible_con_ofertas_increibles: "increíble con ofertas increíbles",
	multiples_soluciones_demandas_especiales: "Múltiples soluciones para tus demandas especiales",
	categorias: "Categorías",
	servicios: "Servicios",
	proveedores_lideres: "Proveedores Líderes",
	centro: "CENTRO",

	/**
	  * Modals
	  * Shop/components/Modals
	  */
	//ModalAdress.js
	agregar_direccion: "Agregar dirección",
	direccion: "Dirección",
	nombre_completo: "Nombre Completo",
	numero_exterior: "Número exterior",
	numero_interior: "Número interior",
	cp: "Código postal",
	estado: "Estado",
	provincia: "Provincia",
	ciudad: "Ciudad",
	selecciona_colonia: "Selecciona tu colonia",
	colonia: "Colonia",
	instrucciones_entrega: "Instrucciones de entrega",
	telefono: "Teléfono",
	guardar: "Guardar",
	//ModalContactNow.js
	mensaje: "Mensaje",
	mensaje_enviado: "Mensaje enviado",
	enviar: "Enviar",
	mensaje_para: "Mensaje para",
	//ModalCotizar.js
	cotizacion_enviada: "Cotización Enviada",
	destino_de_la_mercancia: "Destino de la mercancía",
	elige_pais: "Elige tu país",
	pais: "País",
	elige_ciudad: "Elige tu ciudad",
	ciudad: "Ciudad",
	selecciona_servicios_cotizar: "Selecciona servicios a cotizar",
	si: "Si",
	no: "No",
	cargo_internacional: "Cargo Internacional",
	seguro: "Seguro",
	despacho_aduana: "Despacho de aduana",
	entrega_en_destino: "Entrega en destino",
	asesoria: "Asesoría",

	/**
	  * ProductCard
	  * Shop/components/ProductCard
	  */
	precio: "Precio",
	pieza: "Pieza",
	orden: "Orden",
	piezas: "Piezas",

	/**
	  * Proximamente
	  * Shop/components/Proximamente
	  */
	en_construccion: "En Construcción",
	vuelve_mas_tarde: "Vuelve más tarde para disfrutar de todo lo que tenemos para ti",

	/**
	  * 404
	  * Shop/pages/404/index.js
	  */
	lo_sentimos: "LO SENTIMOS",
	no_pudimos_encontrar_pagina: "no pudimos encontrar la página que buscabas",
	intenta_usar_barra_busqueda: "Intenta usar la barra de búsqueda o visita la",
	pagina_principal: "Página Principal",

	/**
	  * /aboutus
	  */
	//Shop/pages/aboutus/Components/Home/index.js y Shop/pages/proveedor/Components/Home/index.js
	mensaje_enviado: "Mensaje enviado",
	revisa_mensajes_en_panel: "Revisa tus mensajes en el panel",
	aboutUs: "Acerca de nosotros",
	perfil_de_la_empresa: "Perfil de la empresa",
	capacidad_comercial: "Capacidad Comercial",
	capacidad_de_produccion: "Capacidad de producción",
	ver_empresa: "Ver empresa",
	contactar_al_proveedor: "Contactar al proveedor",
	chatea_con_el_proveedor: "Chatea con el proveedor",
	tipo_negocio: "Tipo de negocio",
	productos_principales: "Productos principales",
	area_de_planta: "Área de planta",
	certificacion_del_sistema: "Certificación del sistema de gestión",
	terminos_de_entrega: "Términos de entrega",
	tipo_de_pago: "Tipo de pago",
	porcentaje_de_exportacion: "Porcentaje de exportación",
	mercados_principales: "Mercados principales",
	puerto_cercano: "Puerto cercano",
	numero_de_licencia_de_exportacion: "Número de licencia de exportación",
	archivo_de_licencia_de_exportacion: "Archivo de la licencia de exportación",
	direccion_operativa_de_la_empresa: "Dirección operativa de la empresa",
	total_de_empleados: "Total de empleados",
	tamaño_de_oficina: "Tamaño de la oficina",
	produccion_anual_de_productos_principales: "Producción anual de los productos principales",
	por_encima: "Por encima",
	album_de_la_empresa: "Álbum de la empresa",
	verificado: "Verificado",
	certificados: "Certificados",
	elementos: "Elementos",

	/**
	  * /account_suspended
	  */
	//Shop/pages/account_suspended/index.js
	cuenta_suspendida: "Cuenta Suspendida",

	/**
	  * /bolsa_trabajo
	  */
	//Shop/pages/bolsa_trabajo/Components/Bolsa/index.js
	si_consideras_formar_parte_equipo: "Si consideras que puedes formar parte del equipo, te invitamos a que nos compartas tus datos",
	nivel_estudios: "Nivel de estudios",
	puesto_solicitado: "Puesto Solicitado",
	nota: "Nota",

	/**
	  * /cat
	  */
	//Shop/pages/cat/Components/Categoria/index.js
	grupos_de_productos: "Grupos de productos",

	/**
	  * /faq
	  */
	//Shop/pages/faq/Components/FAQ/index.js
	preguntas_frecuentes: "Preguntas Frecuentes",

	/**
	  * /login
	  */
	//Shop/pages/login/Components/Login/index.js
	credenciales_no_coinciden: "Las credenciales no coinciden con nuestras bases de datos intentelo más tarde",
	crea_una_cuenta_y_disfruta: "Crea una cuenta y disfruta de los Beneficios de",
	contraseña: "Contraseña",
	entrar: "Entrar",
	registrate_aqui: "Registrate aquí",
	olvide_mi_contraseña: "Olvidé mi contraseña",
	no_tienes_cuenta: "¿No tienes una cuenta?",

	/**
	  * /messages
	  */
	//Shop/pages/messages/Components/Messages/index.js
	miembro_diamante: "Miembro Diamante",
	desde: "Desde",
	proveedor_auditado: "Proveedor auditado",
	visto: "visto",
	enviado: "enviado",
	recibido: "recibido",

	/**
	  * /micarrito
	  */
	//Shop/pages/micarrito/Components/Carrito/index.js
	algunos_articulos_en_carrito_estan_agotados: "Algunos artículos en tu carrito están agotados",
	proceder_al_pago: "Proceder al pago",
	tu_carrito_esta_vacio: "Tu carrito esta vacío",
	sin_atributo: "Sin atributo",
	sin_descripcion: "Sin descripcion",
	disponible: "Disponible",
	stock: "Existencias",
	comprar: "Comprar",
	eliminar: "Eliminar",
	guardar_mas_tarde: "Guardar para más tarde",
	comprar_articulos_seleccionados: "Comprar articulos seleccionados",
	//Shop/pages/micarrito/Components/StattusBar/index.js
	CARRITO: "CARRITO",
	DIRECCION: "DIRECCIÓN",
	OPCIONES_RECOLECCION: "OPCIONES RECOLECCIÓN",
	PAGO: "PAGO",
	FINALIZAR: "FINALIZAR",
	/*
	   *
	   * /micarrito/direccion
	   */
	//Shop/pages/micarrito/direccion/Components/Direccion/index.js
	direccion_eliminada: "Dirección eliminada",
	telefono_incorrecto: "Teléfono incorrecto",
	no_dejes_pasar_el_momento: "No dejes pasar este momento",
	registrate: "Regístrate",
	HOLA: "HOLA",
	recolectar: "Recolectar",
	direccion_recoleccion: "Dirección de recolección",
	direccion: "Dirección",
	detalles_quien_recoge: "Detalles de quién recoge el paquete",
	elige_direccion_envio: "Elige tu dirección de envío",
	recoger_en_sucursal: "¿Recoger en sucursal?",
	añadir_direccion: "Añadir dirección",
	enviar_paquete_a: "Enviar paquete a",
	//Shop/pages/micarrito/direccion/Components/Direccion/new.js
	calle: "Calle",
	opcional: "Opcional",
	instrucciones_entrega: "Instrucciones de entrega",
	/*
	   *
	   * /micarrito/envio
	   */
	//Shop/pages/micarrito/envio/Components/Envio/index.js
	recogelo_tu_mismo: "Recógelo tú mismo",
	ver_mapa: "Ver mapa",
	horario_de_atencion: "Horario de atención",
	hora: "Hora",
	dias: "Días",
	economico: "Economico",
	paquete: "Paquete",
	elija_una_opcion: "Elija una opción",
	/*
	   *
	   * /micarrito/finaliza
	   */
	//Shop/pages/micarrito/finaliza/Components/Finalizar/index.js
	fecha_desconocida_consulte_su_guia: "Fecha desconocida consulte su guía en fedex.com",
	gracias_por_tu_pedido: "Gracias por tu pedido",
	tu_pedido_se_hizo_exitosamente: "Tu pedido se hizo exitosamente",
	fecha_entrega_estimada: "Fecha de entrega estimada",
	despues_de_validar_el_pago: "después de validar el pago",
	pedido: "Pedido",
	imprimir: "Imprimir",
	comprobante: "comprobante",
	imprime_o_muestra_este_comprobante: "imprime o muestra este comprobante en nuestra sucursal, junto con una identificación oficial para recoger tu pedido",
	articulos: "Articulos",
	codigo: "Código",
	cantidad: "Cantidad",
	descubre_mas_productos: "Descubre más productos",
	/*
	   *
	   * /micarrito/pago
	   */
	//Shop/pages/micarrito/pago/Components/Pago/index.js
	seleccione_articulos: "Seleccione Artículos",
	cupon_aplicado: "Cupón apicado",
	cupon_no_valido: "Cupón no válido",
	selecciona_un_metodo_pago: "Selecciona un metodo de pago",
	tarjeta_de_credito: "Tarjeta de credito",
	tarjeta_de_debito: "Tarjeta de debito",
	paga_en_oxxo: "Paga en oxxo",
	resumen: "Resumen",
	cupon: "Cupón",
	descuento_no_aplicable_a_gastos_envio: "El descuento no aplica a los costos de envío.",
	canjear_cupon: "Canjear cupón",
	pago_procesado_de_forma_segura: "Pago procesado de forma segura",
	problemas_con_tu_pago: "Problemas con tu pago?",
	verifica_que_tengas_fondos: "Verifica que tengas fondos en tu tarjeta",
	verifica_que_informacion: "Verifica que la información sea correcta",
	recuerda_activar_3d_en_tu_banco: "Recuerda que es importante activar 3D Secure con tu banco para poder realizar pagos en línea",
	proveedor_de_envio: "Proveedor de envío",
	fecha_entrega_estimada: "Fecha de entrega estimada",
	nombre_del_producto: "Nombre del producto",
	//Shop/pages/micarrito/pago/Components/Pago/metodos/oxxoPay.js nota: oxxo solo esta disponible en mexico
	recuerda_que_tienes_3_dias_para_pagar_en_oxxo: "Recuerda que tienes 3 días a partir de hoy para realizar tu pago en OXXO",
	ficha_digital_no_es_necesario_imprimir: "Ficha digital, No es necesario imprimir",
	monto_pagar: "Monto a pagar",
	oxxo_cobra_comision: "OXXO cobrará una comisión adicional al momento de realizar el pago.",
	click_para_guardar: "Click para Guardar",
	REFERENCIA: "REFERENCIA",
	instrucciones: "Instrucciones",
	guardar_esta_ficha: "Guardar esta ficha",
	acude_a_oxxo: "Acude a la tienda OXXO más cercana",
	indica_en_caja_que_quieres_pagar_con_oxxopay: "Indica en caja que quieres realizar un pago de OXXOPay",
	muestra_al_cajero_referencia: "Muestra el al cajero el número de referencia",
	realiza_pago_correspondiente: "Realiza el pago correspondiente",
	conserva_comprobante: "Conserva tu comprobante de pago",
	encuentra_aqui: "Encuentra aquí",
	al_completar_pago_recibiras_correo_de_confirmacion: "Al completar estos pasos recibirás un correo de confirmación de",
	//Shop/pages/micarrito/pago/Components/Pago/metodos/payPal.js
	paypal_locale: "es_MX",
	//Shop/pages/micarrito/pago/Components/Pago/metodos/santander.js
	agregar_tarjeta: "Agregar tarjeta",

	/*
	   *
	   * /miperfil
	   */
	//Shop/pages/miperfil/index.js
	mi_cuenta: "Mi cuenta",
	apodo: "Apodo",
	mis_pedidos: "Mis pedidos",
	mis_direcciones: "Mis direcciones",
	mi_perfil: "Mi perfil",
	cerrar_sesion: "Cerrar Sesión",
	/*
	   *
	   * /miperfil/datos
	   */
	//Shop/pages/miperfil/datos/Components/MiPerfil/index.js
	tus_datos_se_actualizaron: "Tus datos se actualizaron correctamente",
	verifique_sus_datos: "Verifique sus datos",
	ingresa_nuevos_datos: "Ingresa los nuevos datos",
	confirmar_contraseña: "Confirmar contraseña",
	las_contraseñas_no_coinciden: "Las contraseñas no coinciden",
	/*
	   *
	   * /miperfil/misdirecciones
	   */
	//Shop/pages/miperfil/misdirecciones/Components/Direcciones/index.js
	direccion_agregada: "Dirección agregada",
	direccion_actualizada: "Dirección actualizada",
	direccion_eliminada: "Dirección eliminada",
	enviar_a: "Enviar a",

	/*
	   *
	   * /pedidos
	   */
	//Shop/pages/pedidos/Components/Pedidos/index.js
	carrito_actualizado: "Carrito actualizado",
	no_contamos_con_numero_de_productos_en_stock: "No contamos con ese número de productos en stock",
	pedidos_en_curso: "Pedidos en curso",
	pedidos: "Pedidos",
	cotizaciones: "Cotizaciones",
	no_se_encontraron_pedidos: "No se encontraron pedidos",
	llegando_el: "Llegando el",
	consulta_en_24_horas_progreso: "Consulta en 24 horas. El progreso",
	entrega_en_sucursal: "Entrega en sucursal",
	producto: "Producto",
	descripcion: "Descripción",
	pedido_el: "Pedido el",
	pagado: "Pagado",
	paquete_en_camino: "Paquete en camino",
	paquete_listo_para_recoger: "Paquete listo para recoger",
	paquete_entregado: "Paquete entregado",
	seguir_pedido: "Seguir pedido",
	recoger_en: "Recoger en",
	ver_detalles: "Ver detalles",
	evaluar_producto: "Evaluar producto",
	precio_del_envio: "Precio del envio",
	entregado: "Entregado",
	pendiente: "Pendiente",
	cotizacion: "Cotización",
	producto_cotizado: "Producto cotizado",
	proveedor: "Proveedor",
	archivo: "Archivo",

	/*
	   *
	   * /pedidos/pedido
	   */
	//Shop/pages/pedidos/pedido/Components/Pedido/index.js
	datos_de_envio: "Datos de envío",
	tipo_de_envio: "Tipo de envío",
	total: "Total",
	subtotal: "Subtotal",
	numero_de_guia: "Número de Guia",
	recoleccion: "Recolección",

	/*
	   *
	   * /product
	   */
	//Shop/pages/product/Components/Producto/index.js
	lo_sentimos_no_puedes_agregar_mas_productos: "Lo sentimos no puedes agregar mas productos de este tipo, haz superado el stock",
	lo_sentimos_no_hay_stock_disponible: "Lo sentimos, por el momento no hay stock disponible",
	compra_desde_mexico: "Compra desde México",
	producto_no_disponible: "Producto no disponible",
	numero_minimo_de_piezas: "Número minimo de piezas",
	añadir_al_carrito: "Añadir al carrito",
	solicitar_cotizacion_especial: "Solicitar cotizacion especial",
	cantidad_a_cotizar: "Cantidad a cotizar",
	cotizar: "Cotizar",
	comprar_ya: "Comprar ya",
	envio_desde: "Envío desde",
	vendido_por: "Vendido por",
	acerca_de_este_producto: "Acerca de este producto",
	sin_descripcion: "Sin descripción",
	colores: "Colores",
	plazo_de_entrega: "Plazo de entrega",
	tiempo: "Tiempo",
	personalizacion: "Personalización",
	materiales: "Materiales",
	protecciones: "Protecciones",
	protege_tus: "Protege tus",
	detalles_del_producto: "Detalles del producto",
	descripcion_general: "Descripción general",
	numero_de_modelo: "Número de modelo",
	muestra: "Muestra",
	condiciones_de_pago: "Condiciones de pago",
	tiempo_de_entrega: "Tiempo de entrega",
	especificaciones: "Especificaciones",
	origen: "Origen",
	material: "Material",
	marca_comercial: "Marca comercial",
	capacidad_de_produccion: "Capacidad de producción",
	uso_especial: "Uso especial",
	detalles_de_embalaje: "Detalles de embalaje",
	codigo_hs: "Código HS",
	descripcion_detallada: "Descripción Detallada",
	//Shop/pages/product/Components/Producto/Resenia.js
	calificacion: "Calificación",
	calificacion_por_estrellas: "Calificación por estrellas",
	de: "de",
	comentarios: "Comentarios",
	reportar: "Reportar",

	/*
	   *
	   * /recuperar
	   */
	//Shop/pages/recuperar/Components/Recuperar/index.js
	te_enviamos_un_correo_de_recuperacion: "Te enviamos un correo de recuperación",
	ingresa_la_nueva_contraseña_para: "Ingresa la nueva contraseña para",
	// /recuperar/token
	link_expirado: "Link expirado",
	/*
	   *
	   * /registro
	   */
	//Shop/pages/registro/Components/Registro/index.js
	ya_tienes_cuenta: "Ya tienes cuenta?",

	/*
	   *
	   * /reviews
	   */
	//Shop/pages/reviews/index.js
	mis_comentarios: "Mis Comentarios",
	evaluar_producto: "Evaluar producto",
	gracias_por_el_comentario: "Gracias por el comentario, ha sido enviado",
	tu_comentario_esta_siendo_procesado: "Tu comentario esta siendo procesado, esto puede tardar. Agradecemos tu paciencia",
	calificacion_general: "Calificación general",
	titulo_del_comentario: "Título del comentario",
	agregar_un_comentario: "Agregar un comentario",

	/**
	  * /search
	  * Shop/pages/search/Components/Search/index.js
	  */
	desea_eliminar_historial_busquedas: "¿Deseas eliminar tu historial de busquedas?",
	busqueda_reciente: "Búsqueda reciente",
	no_se_han_realizado_busquedas: "No se han realizado búsquedas recientes",
	/** * /search/keyword
	  * Shop/pages/search/keyword/Components/ResultSearch/index.js
	  */
	lo_sentimos_no_hay_resultados: "Lo sentimos no se han encontrado resultados, prueba con otra búsqueda",
	producto_destacado: "Producto destacado",

};

export default esTranslations;
