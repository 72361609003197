const enTranslations = {

	/**
	  * Global
	  */
	enviar: "Send",
	cancelar: "Cancel",
	continuar: "Continue",
	guardar: "Save",
	volver: "Back",
	editar: "Edit",
	cargando: "Loading ...",

	/**
	  * Nav
	  * Shop/components/Nav y FilterNav
	  */
	notProducts: "No Products Yet",
	search: "Search",
	myShopping: "My Shopping",
	hello: "Hello",
	messages: "Messages",
	inquieryBasket: "Inquiery Basket",
	products: "Products",
	aboutUs: "About us",
	homePage: "Home",
	sign_in: "Sign In",
	join: "Join",
	logout: "Log Out",
	//FilterNav
	filtros: "Filter",
	cerrar: "Close",
	categorias: "Categories",
	departamentos: "Departaments",
	ordenar_por: "Order by",
	precio_bajo_alto: "Price low-high",
	precio_alto_bajo: "Price high-low",
	mas_nuevo: "Newest",

	/**
	  * Home
	  * Shop/components/Home
	  */
	bestSeller: "Best Seller",
	new: "New",
	recentlyView: "Recently Viewed",

	/**
	  * Footer
	  * Shop/components/Footer
	  */
	nombre: "Name",
	correo: "Email",
	mensaje: "Message",
	servicioCliente: "Customer Service",
	envianosCorreo: "Send us an email",
	uneteAFamilia: "Join the family to receive news and updates about our products",
	quienesSomos: "Who we are?",
	avisoPrivacidad: "Privacy Policy",
	trabajaConNosotros: "Work with us",
	paraClientes: "For Customers",
	pagosEnvios: "Payments and Shipping",
	politicasCompra: "Purchase Policies",
	redesSociales: "Social Network",
	todosLosDerechosReservados: "All rights reserved",

	/**
	  * Banner2.js
	  * Shop/components/Home/BAnner2.js
	  */
	enero: "January",
	febrero: "February",
	marzo: "March",
	abril: "April",
	mayo: "May",
	junio: "June",
	julio: "July",
	agosto: "August",
	septiempre: "September",
	octubre: "October",
	noviembre: "November",
	diciembre: "December",
	increible_con_ofertas_increibles: "incredible with incredible offers",
	multiples_soluciones_demandas_especiales: "Multiple solutions for your special demands",
	categorias: "Categories",
	servicios: "Services",
	proveedores_lideres: "Leading Suppliers",
	centro: "CENTER",

	/**
	  * Modals
	  * Shop/components/Modals
	  */
	//ModalAdress.js
	agregar_direccion: "Add address",
	direccion: "Address",
	nombre_completo: "Full Name",
	numero_exterior: "Exterior Number",
	numero_interior: "Interior Number",
	cp: "Postal Code",
	estado: "State",
	provincia: "Province",
	ciudad: "City",
	selecciona_colonia: "Select your neighborhood",
	colonia: "Neighborhood",
	instrucciones_entrega: "Delivery Instructions",
	telefono: "Phone",
	guardar: "Save",
	//ModalContactNow.js
	mensaje: "Message",
	mensaje_enviado: "Message sent",
	enviar: "Send",
	mensaje_para: "Message for",
	//ModalCotizar.js
	cotizacion_enviada: "Quote Sent",
	destino_de_la_mercancia: "Destination of the goods",
	elige_pais: "Choose your country",
	pais: "Country",
	elige_ciudad: "Choose your city",
	ciudad: "City",
	selecciona_servicios_cotizar: "Select services to quote",
	si: "Yes",
	no: "Not",
	cargo_internacional: "International Freight",
	seguro: "Insurance",
	despacho_aduana: "Customs Clearance",
	entrega_en_destino: "Delivery at Destination",
	asesoria: "Consulting",

	/**
	  * ProductCard
	  * Shop/components/ProductCard
	  */
	precio: "Price",
	pieza: "Piece",
	orden: "Order",
	piezas: "Pieces",

	/**
	  * Proximamente
	  * Shop/components/Proximamente
	  */
	en_construccion: "Under Construction",
	vuelve_mas_tarde: "Come back later to enjoy everything we have for you",

	/**
	  * 404
	  * Shop/pages/404/index.js
	  */
	lo_sentimos: "SORRY",
	no_pudimos_encontrar_pagina: "we couldn't find the page you were looking for",
	intenta_usar_barra_busqueda: "Try using the search bar or visit the",
	pagina_principal: "Home Page",

	/**
	  * /aboutus
	  */
	//Shop/pages/aboutus/Components/Home/index.js y Shop/pages/proveedor/Components/Home/index.js
	mensaje_enviado: "Message sent",
	revisa_mensajes_en_panel: "Check your messages in the panel",
	aboutUs: "About Us",
	perfil_de_la_empresa: "Company Profile",
	capacidad_comercial: "Commercial Capacity",
	capacidad_de_produccion: "Production Capacity",
	ver_empresa: "View company",
	contactar_al_proveedor: "Contact the supplier",
	chatea_con_el_proveedor: "Chat with the supplier",
	tipo_negocio: "Business Type",
	productos_principales: "Main Products",
	area_de_planta: "Plant Area",
	certificacion_del_sistema: "Management System Certification",
	terminos_de_entrega: "Delivery Terms",
	tipo_de_pago: "Payment Type",
	porcentaje_de_exportacion: "Export Percentage",
	mercados_principales: "Main Markets",
	puerto_cercano: "Nearest Port",
	numero_de_licencia_de_exportacion: "Export License Number",
	archivo_de_licencia_de_exportacion: "Export License File",
	direccion_operativa_de_la_empresa: "Company Operating Address",
	total_de_empleados: "Total Employees",
	tamaño_de_oficina: "Office Size",
	produccion_anual_de_productos_principales: "Annual Production of Main Products",
	por_encima: "Above",
	album_de_la_empresa: "Company Album",
	verificado: "Verified",
	certificados: "Certificates",
	elementos: "Items",

	/**
	  * /account_suspended
	  */
	//Shop/pages/account_suspended/index.js
	cuenta_suspendida: "Account suspended",

	/**
	  * /bolsa_trabajo
	  */
	//Shop/pages/bolsa_trabajo/Components/Bolsa/index.js
	si_consideras_formar_parte_equipo: "If you believe you can be part of the team, we invite you to share your details with us",
	nivel_estudios: "Education Level",
	puesto_solicitado: "Position Applied For",
	nota: "Note",

	/**
	  * /cat
	  */
	//Shop/pages/cat/Components/Categoria/index.js
	grupos_de_productos: "Product Groups",

	/**
	  * /faq
	  */
	//Shop/pages/faq/Components/FAQ/index.js
	preguntas_frecuentes: "Frequently Asked Questions",

	/**
	  * /login
	  */
	//Shop/pages/login/Components/Login/index.js
	credenciales_no_coinciden: "The credentials do not match our database, please try again later",
	crea_una_cuenta_y_disfruta: "Create an account and enjoy the benefits of",
	contraseña: "Password",
	entrar: "Log In",
	registrate_aqui: "Sign up here",
	olvide_mi_contraseña: "Forgot my password",
	no_tienes_cuenta: "Don\'t have an account?",

	/**
	  * /messages
	  */
	//Shop/pages/messages/Components/Messages/index.js
	miembro_diamante: "Diamond Member",
	desde: "Since",
	proveedor_auditado: "Audited Supplier",
	visto: "Viewed",
	enviado: "Sent",
	recibido: "Received",

	/**
	  * /micarrito
	  */
	//Shop/pages/micarrito/Components/Carrito/index.js
	algunos_articulos_en_carrito_estan_agotados: "Some items in your cart are out of stock",
	proceder_al_pago: "Proceed to checkout",
	tu_carrito_esta_vacio: "Your cart is empty",
	sin_atributo: "No attribute",
	sin_descripcion: "No description",
	disponible: "Available",
	stock: "Stock",
	comprar: "Buy",
	eliminar: "Remove",
	guardar_mas_tarde: "Save for later",
	comprar_articulos_seleccionados: "Buy selected items",
	//Shop/pages/micarrito/Components/StattusBar/index.js
	CARRITO: "CART",
	DIRECCION: "ADDRESS",
	OPCIONES_RECOLECCION: "PICKUP OPTIONS",
	PAGO: "PAYMENT",
	FINALIZAR: "FINALIZE",
	/*
	   *
	   * /micarrito/direccion
	   */
	//Shop/pages/micarrito/direccion/Components/Direccion/index.js
	direccion_eliminada: "Address deleted",
	telefono_incorrecto: "Incorrect phone number",
	no_dejes_pasar_el_momento: "Don\'t let this moment pass",
	registrate: "Sign up",
	HOLA: "HELLO",
	recolectar: "Collect",
	direccion_recoleccion: "Collection address",
	direccion: "Address",
	detalles_quien_recoge: "Details of who is picking up the package",
	elige_direccion_envio: "Choose your shipping address",
	recoger_en_sucursal: "Pick up at branch?",
	añadir_direccion: "Add address",
	enviar_paquete_a: "Send package to",
	//Shop/pages/micarrito/direccion/Components/Direccion/new.js
	calle: "Street",
	opcional: "Optional",
	instrucciones_entrega: "Delivery Instructions",
	/*
	   *
	   * /micarrito/envio
	   */
	//Shop/pages/micarrito/envio/Components/Envio/index.js
	recogelo_tu_mismo: "Pick it up yourself",
	ver_mapa: "View map",
	horario_de_atencion: "Business hours",
	hora: "Time",
	dias: "Days",
	economico: "Economical",
	paquete: "Package",
	elija_una_opcion: "Choose an option",
	/*
	   *
	   * /micarrito/finaliza
	   */
	//Shop/pages/micarrito/finaliza/Components/Finalizar/index.js
	fecha_desconocida_consulte_su_guia: "Unknown date, please check your guide at fedex.com",
	gracias_por_tu_pedido: "Thank you for your order",
	tu_pedido_se_hizo_exitosamente: "Your order was successfully placed",
	fecha_entrega_estimada: "Estimated delivery date",
	despues_de_validar_el_pago: "After payment validation",
	pedido: "Order",
	imprimir: "Print",
	comprobante: "Receipt",
	imprime_o_muestra_este_comprobante: "Print or show this receipt at our branch, along with an official ID to pick up your order",
	articulos: "Items",
	codigo: "Code",
	cantidad: "Quantity",
	descubre_mas_productos: "Discover more products",
	/*
	   *
	   * /micarrito/pago
	   */
	//Shop/pages/micarrito/pago/Components/Pago/index.js
	seleccione_articulos: "Select Items",
	cupon_aplicado: "Coupon applied",
	cupon_no_valido: "Invalid coupon",
	selecciona_un_metodo_pago: "Select a payment method",
	tarjeta_de_credito: "Credit card",
	tarjeta_de_debito: "Debit card",
	paga_en_oxxo: "Pay at OXXO",
	resumen: "Summary",
	cupon: "Coupon",
	descuento_no_aplicable_a_gastos_envio: "The discount does not apply to shipping costs.",
	canjear_cupon: "Redeem coupon",
	pago_procesado_de_forma_segura: "Payment processed securely",
	problemas_con_tu_pago: "Problems with your payment?",
	verifica_que_tengas_fondos: "Check that you have funds on your card",
	verifica_que_informacion: "Verify that the information is correct",
	recuerda_activar_3d_en_tu_banco: "Remember to activate 3D Secure with your bank to make online payments",
	proveedor_de_envio: "Shipping provider",
	fecha_entrega_estimada: "Estimated delivery date",
	nombre_del_producto: "Product name",
	//Shop/pages/micarrito/pago/Components/Pago/metodos/oxxoPay.js nota: oxxo solo esta disponible en mexico
	recuerda_que_tienes_3_dias_para_pagar_en_oxxo: "Remember you have 3 days from today to pay at OXXO",
	ficha_digital_no_es_necesario_imprimir: "Digital slip, No need to print",
	monto_pagar: "Amount to pay",
	oxxo_cobra_comision: "OXXO will charge an additional fee when making the payment.",
	click_para_guardar: "Click to Save",
	REFERENCIA: "REFERENCE",
	instrucciones: "Instructions",
	guardar_esta_ficha: "Save this slip",
	acude_a_oxxo: "Go to the nearest OXXO store",
	indica_en_caja_que_quieres_pagar_con_oxxopay: "Tell the cashier you want to pay with OXXOPay",
	muestra_al_cajero_referencia: "Show the cashier the reference number",
	realiza_pago_correspondiente: "Make the corresponding payment",
	conserva_comprobante: "Keep your payment receipt",
	encuentra_aqui: "Find here",
	al_completar_pago_recibiras_correo_de_confirmacion: "After completing these steps, you will receive a confirmation email from",
	//Shop/pages/micarrito/pago/Components/Pago/metodos/payPal.js
	paypal_locale: "es_MX",
	//Shop/pages/micarrito/pago/Components/Pago/metodos/santander.js
	agregar_tarjeta: "Add card",

	/*
	   *
	   * /miperfil
	   */
	//Shop/pages/miperfil/index.js
	mi_cuenta: "My account",
	apodo: "Nickname",
	mis_pedidos: "My orders",
	mis_direcciones: "My addresses",
	mi_perfil: "My profile",
	cerrar_sesion: "Log out",
	/*
	   *
	   * /miperfil/datos
	   */
	//Shop/pages/miperfil/datos/Components/MiPerfil/index.js
	tus_datos_se_actualizaron: "Your data has been updated successfully",
	verifique_sus_datos: "Verify your data",
	ingresa_nuevos_datos: "Enter the new data",
	confirmar_contraseña: "Confirm password",
	las_contraseñas_no_coinciden: "The passwords do not match",
	/*
	   *
	   * /miperfil/misdirecciones
	   */
	//Shop/pages/miperfil/misdirecciones/Components/Direcciones/index.js
	direccion_agregada: "Address added",
	direccion_actualizada: "Address updated",
	direccion_eliminada: "Address deleted",
	enviar_a: "Send to",

	/*
	   *
	   * /pedidos
	   */
	//Shop/pages/pedidos/Components/Pedidos/index.js
	carrito_actualizado: "Cart updated",
	no_contamos_con_numero_de_productos_en_stock: "We don't have that number of products in stock",
	pedidos_en_curso: "Orders in progress",
	pedidos: "Orders",
	cotizaciones: "Quotes",
	no_se_encontraron_pedidos: "No orders found",
	llegando_el: "Arriving on",
	consulta_en_24_horas_progreso: "Check in 24 hours. The progress",
	entrega_en_sucursal: "Store pickup",
	producto: "Product",
	descripcion: "Description",
	pedido_el: "Order on",
	pagado: "Paid",
	paquete_en_camino: "Package on the way",
	paquete_listo_para_recoger: "Package ready for pickup",
	paquete_entregado: "Package delivered",
	seguir_pedido: "Track order",
	recoger_en: "Pick up at",
	ver_detalles: "View details",
	evaluar_producto: "Rate product",
	precio_del_envio: "Shipping price",
	entregado: "Delivered",
	pendiente: "Pending",
	cotizacion: "Quote",
	producto_cotizado: "Quoted product",
	proveedor: "Supplier",
	archivo: "File",

	/*
	   *
	   * /pedidos/pedido
	   */
	//Shop/pages/pedidos/pedido/Components/Pedido/index.js
	datos_de_envio: "Shipping details",
	tipo_de_envio: "Shipping type",
	total: "Total",
	subtotal: "Subtotal",
	numero_de_guia: "Tracking number",
	recoleccion: "Collection",

	/*
	   *
	   * /product
	   */
	//Shop/pages/product/Components/Producto/index.js
	lo_sentimos_no_puedes_agregar_mas_productos: "Sorry, you cannot add more products of this type, you have exceeded the stock",
	lo_sentimos_no_hay_stock_disponible: "Sorry, there is currently no stock available",
	compra_desde_mexico: "Shop from Mexico",
	producto_no_disponible: "Product not available",
	numero_minimo_de_piezas: "Minimum number of pieces",
	añadir_al_carrito: "Add to cart",
	solicitar_cotizacion_especial: "Request special quote",
	cantidad_a_cotizar: "Quantity to quote",
	cotizar: "Quote",
	comprar_ya: "Buy now",
	envio_desde: "Shipping from",
	vendido_por: "Sold by",
	acerca_de_este_producto: "About this product",
	sin_descripcion: "No description",
	colores: "Colors",
	plazo_de_entrega: "Delivery time",
	tiempo: "Time",
	personalizacion: "Customization",
	materiales: "Materials",
	protecciones: "Protections",
	protege_tus: "Protect your",
	detalles_del_producto: "Product details",
	descripcion_general: "General description",
	numero_de_modelo: "Model number",
	muestra: "Sample",
	condiciones_de_pago: "Payment terms",
	tiempo_de_entrega: "Delivery time",
	especificaciones: "Specifications",
	origen: "Origin",
	material: "Material",
	marca_comercial: "Trade brand",
	capacidad_de_produccion: "Production capacity",
	uso_especial: "Special use",
	detalles_de_embalaje: "Packaging details",
	codigo_hs: "HS code",
	descripcion_detallada: "Detailed description",
	//Shop/pages/product/Components/Producto/Resenia.js
	calificacion: "Rating",
	calificacion_por_estrellas: "Star rating",
	de: "of",
	comentarios: "Comments",
	reportar: "Report",

	/*
	   *
	   * /recuperar
	   */
	//Shop/pages/recuperar/Components/Recuperar/index.js
	te_enviamos_un_correo_de_recuperacion: "We have sent you a recovery email",
	ingresa_la_nueva_contraseña_para: "Enter the new password for",
	// /recuperar/token
	link_expirado: "Link expired",
	/*
	   *
	   * /registro
	   */
	//Shop/pages/registro/Components/Registro/index.js
	ya_tienes_cuenta: "Do you already have an account?",

	/*
	   *
	   * /reviews
	   */
	//Shop/pages/reviews/index.js
	mis_comentarios: "My Comments",
	evaluar_producto: "Rate product",
	gracias_por_el_comentario: "Thank you for the comment, it has been submitted",
	tu_comentario_esta_siendo_procesado: "Your comment is being processed, this may take a while. We appreciate your patience",
	calificacion_general: "Overall rating",
	titulo_del_comentario: "Comment title",
	agregar_un_comentario: "Add a comment",

	/**
	  * /search
	  * Shop/pages/search/Components/Search/index.js
	  */
	desea_eliminar_historial_busquedas: "Do you want to delete your search history?",
	busqueda_reciente: "Recent search",
	no_se_han_realizado_busquedas: "No recent searches have been made",
	/** * /search/keyword
	  * Shop/pages/search/keyword/Components/ResultSearch/index.js
	  */
	lo_sentimos_no_hay_resultados: "Sorry, no results were found, try another search",
	producto_destacado: "Featured product",

};

export default enTranslations;
