import axios from 'axios';

const instance = axios.create({
	baseURL: 'https://kangoru-shop-api.asiamart.com.mx',
})

/**
  * urls que pueden ser solicitadas y no redirigir
  */
const urlException = [
        '/api/user',
        //'/api/misdirecciones', //se deshabilito por ticket, un usurio no puede comprar si no esta logueado
]
 
instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (401 === error.response.status && !urlException.includes(error.response.config.url)) {
            window.location.replace('/login');
           //createBrowserHistory().push('/login');
           //window.location.reload();
        }
        if(error.response.status===404){
            window.location.replace('/404');
           //createBrowserHistory().push('/404');
           //window.location.reload();
        }

        if(error.response.status===402 && !window.location.href.includes('account_suspended')){
            window.location.replace('/account_suspended');
        }

        return Promise.reject(error);
    });

if (typeof window !== 'undefined') {
        instance.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
        instance.defaults.headers.common['X-Frontend-URL'] = window.location.href;
        instance.defaults.headers.common['X-Frontend-Domain'] = window.location.hostname.replace('www.','');
}

export default instance;
