import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import esTranslations from './langs/es'
import enTranslations from './langs/en'

i18n.use(initReactI18next) // inicializa react-i18next
.init({
	resources: {
		en: {
			translation: enTranslations,
		},
		es: {
			translation: esTranslations,
		},
	},
	lng: 'es', // idioma predeterminado
	fallbackLng: 'es', // idioma de respaldo si no se encuentra la traducción
	interpolation: {
		escapeValue: false, // react-i18next maneja la interpolación de forma automática
	},

});

export default i18n;
