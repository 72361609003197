import React from 'react';

export const DropShipingPageIndex = React.lazy(() => import('./dropshiping'));
export const CatalogoGlobalPageIndex = React.lazy(() => import('./catalogoglobal'));
export const VentasPageIndex = React.lazy(() => import('./ventas'));
export const EntregaPageIndex = React.lazy(() => import('./entrega'));
export const BalanceSummaryPageIndex = React.lazy(() => import('./balance/summary'));
export const PaymentHistoryPageIndex = React.lazy(() => import('./balance/payment-history'));
export const MiBanca = React.lazy(() => import('./mi-cuenta-bancaria'));
export const Domains = React.lazy(() => import('./domains'));
export const SuscripcionPageIndex = React.lazy(() => import('./suscripcion'));
export const PlansPageIndex = React.lazy(() => import('./suscripcion/plans'));
export const PremiumPageIndex = React.lazy(() => import('./suscripcion/plans/premium'));
