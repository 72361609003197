import React from 'react';

//components
export const Dashboard = React.lazy(() => import('./dashboard'));
export const Mensajes = React.lazy(() => import('./Mensajes'));
export const Cotizaciones = React.lazy(() => import('./Cotizaciones'));
export const Cotizacion = React.lazy(() => import('./Cotizaciones/cotizacion'));
export const Visitas = React.lazy(() => import('./Visitas'));
//modules
export { NewProduct, EditProduct, Catalogo } from './Inventarios';
export { Login, Create } from './auth';
export { OrderStatus, ListaProductos } from './Pedidos';
export { PerfilEmpresa, Carrusel, WebConfigs, AvisoPrivacidad, Pickups } from './Configuracion';
export { Faq, Glosario } from './Ayuda';
export { PoliticaCompra, PagosEnvios } from './Policies';
export { ComentariosList, Comentarios } from './Comentarios';
